import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Header from "../components/header"
import Hero from "../components/hero"
import SEO from "../components/seo"
import GenerateButton from "../components/generate-button"
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

function Tip(props) {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const { t } = useTranslation()
  const context = React.useContext(I18nextContext)
  const backgroundThumbnail = getImage(props.data.markdownRemark.frontmatter.backgroundThumbnail)
  const blogThumbnail = getImage(props.data.markdownRemark.frontmatter.blogThumbnail)
  const socialThumbnail = getImage(props.data.markdownRemark.frontmatter.socialThumbnail)

  return (
    <Layout>
      <SEO title={props.data.markdownRemark.frontmatter.title} description={props.data.markdownRemark.frontmatter.description} image={socialThumbnail?.images?.fallback?.src} lang={context.language} />
      <div className="hero-wrapper">
        <GatsbyImage image={backgroundThumbnail} className="hero-wrapper__background" alt="" />
        <Header/>
        <main className="main" id="content">
          <Hero>
            <span className="hero__level">{props.data.markdownRemark.frontmatter.level}</span>
            <h1 className="hero__title">{props.data.markdownRemark.frontmatter.title}</h1>
            <div className="hero__description" dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>

            <p className="social-sharing">
              <span>{t("share-caption")}: </span>
              <a href={"https://www.facebook.com/sharer.php?u=" + url} aria-label={t("facebook-share-caption")} target="_blank" rel="noopener">Facebook</a>
            </p>

            {props.data.markdownRemark.frontmatter.blogURL && <Link to={props.data.markdownRemark.frontmatter.blogURL} className="related-post">
              <GatsbyImage image={blogThumbnail} className="related-post__thumbnail" alt="" />
              <div className="related-post__caption">
                <h2 className="related-post__title">{t("blog-post-link-title")}</h2>
              </div>
            </Link>}
            
            <GenerateButton currentSlug={props.data.markdownRemark.fields.slug}>
              {t("generate-btn-caption-2")}
            </GenerateButton>
          </Hero>
        </main>
      </div>
    </Layout>
  )
}

export default Tip

export const query = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        description
        lang
        level
        title
        blogURL
        backgroundThumbnail {
          childImageSharp {
            gatsbyImageData(width: 1920)
          }
        }
        socialThumbnail {
          childImageSharp {
            gatsbyImageData(width: 1600)
          }
        }      
        blogThumbnail {
          childImageSharp {
            gatsbyImageData(width: 256)
          }
        }
      }
      fields {
        slug
      }
      html
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
